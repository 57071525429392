import { title } from "process"

const Config = {
  phone: '01273833722',
  phoneDisplay: '01273 833 722',
  email: 'info@breakeven.org.uk',
  social: {
    twitter: {
      url: '/social/links/twitter',
      name: 'BreakevenGC'
    },
    x: {
      url: '/social/links/x',
      name: 'BreakevenGC'
    },
    instagram: {
      url: '/social/links/instagram',
      name: 'BreakevenGC'
    },
    facebook: {
      url: '/social/links/facebook',
      name: 'BreakevenGC'
    },
    linkedin: {
      url: '/social/links/linkedin',
      name: 'BreakevenUK'
    }
  },
  siteMap: {
    quick: [{
      title: 'Looking for Support?',
      items: [{
        title: 'What is Gambling Harm',
        link: '/what-is-gambling-harm',
        description: 'Gamling harm is when an individual has an urge to gamble regardless of the harmful, negative consequences which can occur. It can have serious, significant impacts on finances, relationships and on the overall wellbeing of not just themselves but also their loved ones. Individuals living with a problematic gambling may have a desire to stop their behaviour, but they are unable to do so.'
      }, {
        title: 'Gambling Harms Treatment',
        link: '/gambling-harms-treatment',
        description: 'The aim of gambling harms treatment counselling at Breakeven is to provide an opportunity for you to work toward increasing your well-being by becoming free of problem gambling.'
      }, {
        title: 'Support Groups',
        link: '/support-groups',
        description: 'Here at Breakeven, we offer multiple support groups.'
      }]
    }, {
      title: 'Steps to Take',
      items: [{
        title: 'Recovery toolkit',
        link: '/recovery-toolkit',
        description: 'Alongside reaching out to one of our team for specialist support, there are also lots of practical tools and resources that you may want to consider when looking at reducing gambling related harm.'
      }, {
        title: 'Self Assessment',
        link: '/self-assessment',
        description: 'Alongside reaching out to one of our team for specialist support, there are also lots of practical tools and resources that you may want to consider when looking at reducing gambling related harm.'
      }, {
        title: 'Client Journey',
        link: '/client-journey',
        description: "At Breakeven, we believe it’s important for our clients to know what expect at each step of their treatment. Our map below shows represents a client’s journey with us, starting at telephone assessment and going all the way through to aftercare."
      }]
    }, {
      title: 'Professionals',
      items: [{
        title: 'Referrals & Screening Questions',
        link: '/professionals/referrals',
        description: 'This form is for professionals to make a direct referral into Breakeven on behalf of someone who is experiencing gambling related harm.'
      }, {
        title: 'Education & Training',
        link: '/professionals/education-and-training',
        description: 'At Breakeven, we offer specialised Gambling Awareness Training which is designed to upskill or train staff about how best to either help or identify people suffering gambling related harm.'
      }, {
        title: 'Support Leaflets',
        link: '/professionals/support-leaflets',
        description: 'We provide a range of Leaflets for you to download or order. Click leaflets to download, or use the form at the bottom of this page to order the copies you require.'
      }]
    }],
    main: [{
      title: 'Support',
      items: [{
        title: 'Gambling Harms Treatment',
        link: '/gambling-harms-treatment',
        info: ''
      }, {
        title: 'Support Groups',
        link: '/support-groups'
      }, {
        title: 'Family & Friends',
        link: '/family-and-friends'
      }, {
        title: 'Support for women',
        link: '/support-groups#wings'
      }, {
        title: 'South Asian Programme',
        link: '/south-asian-programme'
      }, {
        title: 'Contact Us',
        link: '/contact-us'
      }]
    }, {
      title: 'Overview',
      items: [{
        title: 'About Us',
        link: '/about-us'
      }, {
        title: 'What is Gambling Harm?',
        link: 'what-is-gambling-harm'
      }, {
        title: 'Our Offices',
        link: '/offices'
      }, {
        title: 'Waiting List',
        link: '/waiting-list'
      }, {
        title: 'Social Media',
        link: '/socials'
      }, {
        title: 'Podcast',
        link: '/podcast'
      }, {
        title: 'Other Organisations',
        link: '/other-organisations'
      }, {
        title: 'Work for Us',
        link: '/work-for-us'
      }, {
        title: 'Support Us',
        link: '/support-us'
      }]
    }, {
      title: 'Steps to Take',
      items: [{
        title: 'Self Referral',
        link: '/self-referral'
      }, {
        title: 'Self Assessment',
        link: '/self-assessment'
      }, {
        title: 'Client Journey',
        link: '/client-journey'
      }, {
        title: 'Aftercare',
        link: '/aftercare'
      }, {
        title: 'Recovery Toolkit',
        link: '/recovery-toolkit'
      }, {
        title: 'Money Management',
        link: '/recovery-toolkit#money-management'
      }, {
        title: 'Useful Contacts',
        link: '/useful-contacts'
      }]
    }, {
      title: 'Professionals',
      items: [{
        title: 'Referrals & Screening Questions',
        link: '/professionals/referrals'
      }, {
        title: 'Education & Training',
        link: '/professionals/education-and-training'
      }, {
        title: 'Support Leaflets',
        link: '/professionals/support-leaflets'
      }, {
        title: 'Criminal Justice Programme',
        link: '/professionals/criminal-justice-programme'
      }, {
        title: 'Events',
        link: '/professionals/events'
      }]
    }]
  },
  offices: []
}

export default Config
